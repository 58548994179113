.popup {
    font-family: $font-stack;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f7f6f6;
    width: 350px;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: $blueEdilians;
  }

  .rdv{
   
    padding-top:2rem;
    font-size: .8rem;

    
  }
  
  .popup-content {
    text-align: center;
  }
  
  .popup h2 {
    margin-top: 0;
    color: black;

  }
  
  .popup p {
    margin-bottom: 1rem;
  }
  
  .popup button {
    font-weight: 600;
    padding: .8rem 3rem;
    background-color: $blueEdilians;
    color: $white;
    border: none;
    border-radius: $radius;
    cursor: pointer;
  }