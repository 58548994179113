
/****************************
//  ERGO
/****************************/

$border-radius : 8px;
$border-radius-input : 100px;

$fav-height-input : 50px;
//****************************
//  COLORS
//****************************
    $white : #FFFFFF;
    $blueEdilians : #2d2e83;
    $blueFlashEdilians : #40acb6;

	$placeholder : #BEBEBE;

	$background : linear-gradient(180deg, #F7F7FA 0%, #EDEDF1 100%);

    $greenEdilians : #254f65;

    $redEdilians : #774168;

    $redAlert : #fe0000;



	/// DESIGN SYSTEM 2///
	// ***  Background ***



//****************************
//  FONTS
//****************************

	// Sizes
    $light-ftWeight : 400;
    $hard-ftWeight : 600;

    $line-height : 44px;

	$radius : 3px;



	//// DESIGN SYSTEM 3 ////
	@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap');
	$Font-stack-ds3 : 'DM Sans', Helvetica, sans-serif; 
    $font-stack : "Raleway", sans-serif;

	input{ 
		&::placeholder{
			font-family: $font-stack;
		}
	}



//****************************
//  MARGIN & PADDING
//****************************

$margin : 1rem;
$margin-xxsmall : $margin * .125;
$margin-xsmall : $margin * .25;
$margin-small : $margin * .5;
$margin-large : $margin * 2;
$margin-xlarge : $margin * 4;
$margin-xxlarge : $margin * 8;

$padding : 2rem;
$padding-xxsmall : $padding * .125;
$padding-xsmall : $padding * .25;
$padding-small : $padding * .5;
$padding-large : $padding * 2;
$padding-xlarge : $padding * 4;
$padding-xxlarge : $padding * 8;

//****************************
//  BREAKPOINTS
//****************************

$bp-small : 450px;
$bp-medium: 750px;
$bp-large : 960px;
$bp-xlarge :1080px;


.truncate200{
	width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: lowercase;
}


.redError{
	color: $redAlert;
	font-family: $font-stack;
	font-size: 12px;
}

.isSubmit{
	background-color: dimgrey;
    color: linen;
    opacity: 1;

}