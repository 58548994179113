
@import "./utils";
@import "./forms";
@import "./Popup.scss";

html{

		overflow-x:  hidden; 
	
}

header{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-top: 1rem;
	padding-bottom: 1rem;
	position: relative;

	@media screen and (max-width: $bp-medium) {

		display: none;
	  }
}
body{ 
	margin: 0;
	padding: 0;
	line-height: 1.5;
	// font-size: .75rem;
}

.headerWrap{ 
	box-sizing: border-box;
	margin-left: auto;
	margin-right: auto;
}

#serviceDansUrgence{
	background-color: $blueFlashEdilians;
	transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
	position: relative;
	max-height: 433px;

	.container{
		max-width: 1140px;;
		.row{
			width: 100%;
			display: flex;
			.containerColumns{
				width: 100%;
				display: flex;
				position: relative;

				.containerWrap{
					width: 100%;
					position: relative;
					display: flex;
					padding: 10px;
					flex-wrap: wrap;
					align-content: flex-start;
					.containerPosition{
						margin-bottom: 20px;
						width: 100%;
						flex-wrap: wrap;
						align-content: flex-start;
						.containerTitle{
							text-align: center;
							width: 100%;
							.pads{
								padding: 0px 0px 0px 0px;
								text-align: center;
								h2{
									font-weight: 300;
									font-size: 2rem;
									font-family: $font_stack;
									color: $white;
									line-height: 44px;

									@media screen and (max-width: $bp-medium) {
	
										font-size: 1.5rem;
									
										
									}

									
									
								}
							}
						}

					}

				
			
				}

			}
		}
	}

	@media screen and (max-width: $bp-xlarge) {
	
		max-height: 35%;
	}
	

}

#containerInfos{
	width: 100%;
	position: relative;
	color: $white;
	font-family: $font_stack;
	.columnGap{
		max-width: 1140px;
		display: flex;
		margin-right: auto;
		margin-left: auto;
		position: relative;
		.rowGap{
			width: 100%;
			display: flex;
			box-sizing: border-box;

			@media screen and (max-width: $bp-medium) {
	
				max-height: 45%;
				flex-direction: column;
				
			}

			.colGap{
				@media screen and (max-width: $bp-medium) {
	
					padding: 0 0em 1.2rem 0rem;
					width: 100%;
				
					
				}

				width: 33.33%;

				.contentCol{
					margin:  0px 7px 0px 7px;
					width: 100%;
					position: relative;
					display: flex;
					box-sizing: border-box;

					.colWrap{
						align-content: flex-start;
						align-items: flex-start;
						padding: 0% 15% 0% 15%;
			
						.flexRoxElem{
							margin-bottom: 20px;
							width: 100%;
							text-align: center;


							.widgetContainer{
								transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,transform var(--e-transform-transition-duration,.4s);
								box-sizing: border-box;
								text-align: center;
								.elemImg{
									text-align: center;
									box-sizing: border-box;
								

									img{
										width: 71px;
										height: auto;
										max-width: 100%;
										border: none;
										border-radius: 0;
										box-shadow: none;
										vertical-align: middle;
										display: inline-block;
									  
									}
								}

								.textEditor {
									font-size:15px
								}

							}

						}
					}

				}
			}
		}

	}

}

#sos-toiture{

	margin-top: -360px;

	@media screen and (max-width: $bp-medium) {
		margin-top: 0;
	  }

	
}
#background-header{
	padding: 0100px 0px 136px 0px;
	left: 0;

	@media screen and (max-width: $bp-medium) {
		padding: 50px 0px 113px 0px;
	  }


	

	background-image: url(../img/bg-footer.png);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;

	.containerLogo {

		max-width: 1140px;
		display: flex;
		position: relative;
		margin-right: auto;
		margin-left: auto;
		box-sizing: border-box;

		.rowLogo {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			box-sizing: border-box;

			
			@media screen and (max-width: $bp-medium) {
				display: block;
				flex-wrap: none;
			  }

		

			.fakeContainer {
				width: 57.9%;
				position: relative;
				min-height: 1px;
				display: flex;

				
				.divFake{
					width: 100%;
					position: relative;
					display: flex;
				
				}
			  
			}

			.containerEdilians {
				width: 42.1%;
				position: relative;
				min-height: 1px;
				display: flex;

				@media screen and (max-width:$bp-medium) {
					width:auto
				  }

				.divFake{
					width: 100%;
					position: relative;
					display: flex;
					.widgetWrap{
						padding: 10px;

						position: relative;
						width: 100%;
						flex-wrap: wrap;
						align-content: flex-start;
						.divCenter{ 
							text-align: center;
							width: 100%;
							.logoContainer {
								margin : 55px 0px 0px 0px;
								#logo{
	
									//game changer
									width: 45%;

									@media screen and (max-width: $bp-medium) {
										width: 40%;
									  }

		


	
									vertical-align: middle;
									display: inline-block;
	
									height: auto;
									max-width: 100%;
									border: none;
									border-radius: 0;
									box-shadow: none;
	
								}
								
							}
						}

					}

				}



			}


		}
	}

}

.headerSection{ 
	box-sizing: border-box;
	left: 0px;
	.divider{
		padding-top: 2rem;
	}
}

.container{ 
	max-width: 1140px !important;
	display: flex;
	margin-right: auto;
	margin-left: auto;
	position: relative;
	box-sizing: border-box;


}

.containerFlex{ 

	display: flex;
	width: 100%;
	box-sizing: border-box;
}

#textServiceSos{
	width: 63.68%;
	color: $white;
	max-width: 725px;
	@media screen and (max-width: $bp-medium) {
	
		width: 100% !important;
	  }
}

#containerSOS{ 

	@media screen and (max-width: $bp-medium) {
		text-align: center;
		padding-bottom: 2rem;
		display: flex !important;
		min-width: 0px;
	  }

	background-color: $blueEdilians;
	width: 100%;
	position: relative;
	display: flex;
	flex-wrap: wrap;

	min-width: 433px;

	.btnContainer{
		width: 100%;

		.widgetContainer{
			box-sizing: border-box;
			transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,transform var(--e-transform-transition-duration,.4s);
			
			
			.widgetWrapper{
				box-sizing: border-box;
				a{
					background-color: $blueFlashEdilians;
					border-radius: 0;
					font-family: $font_stack;
					font-weight: bolder;
					box-shadow: none;
					text-decoration: none;
					display: inline-block;
					line-height: 1;
					font-size: 15px;
					padding: 12px 24px;
					color: $white;
					text-align: center;
					fill: $white;

					span{
						display: flex;
						justify-content: center;
						box-sizing: border-box;

						span{
							
						}
					}
				}
			}

		}

	}


}
#wrapSOS{
	padding: 30px 50px 30px 50px;
	display: flex;
	position: relative;
	width: 100%;
	flex-wrap: wrap;
	align-content: flex-start;

}

#wrapTitle{ 
	margin-bottom: 20px;
	width: 100%;


}
.titleHeader{ 

	font-family: $font_stack;
	font-weight: $hard-ftWeight;
	line-height: 1;
	box-sizing: border-box;
}


.mainText {
	width: 100%;
	margin-bottom: 20px;
	position: relative;

	.container{

		box-sizing: border-box;
		transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,transform var(--e-transform-transition-duration,.4s);

		.texts{
			font-family: $font_stack;
			font-weight: $light-ftWeight;
			line-height: 24px;
			padding-left: 2px;
			p{
				box-sizing: border-box;
				margin-bottom: .9rem;
				margin-top: 0;
			}


		}

	}
}


#rightFakeContainer{ 
	width: 36.271%;

	@media screen and (max-width: $bp-medium) {
		display: none;
	  }
}

.columnWrap{
	width: 100%;
	position: relative;
	display: flex;

}

.widgetWrap{
	position: relative;
	width: 100%;
	flex-wrap: wrap;
	align-content: flex-start;
  
}